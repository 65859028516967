import { errorStatusMessage } from './errorStatusMessage';

export const locale = {
  ...errorStatusMessage,
  changeLang: 'Change language',
  changePas: 'Change pasword',
  logout: 'Logout',
  other: 'Other',
  successAuth: 'Success authorization',
  notRules: 'Insufficient login privileges',

  newPlayer: 'New player',
  userName: 'Username',
  password: 'Password',
  createAccount: 'Create account',
  '/user-create': 'Cash register - New player',
  '/total': 'Cash register - Cashier transactions',
  '/': 'Menu',
  '/locale': 'Languagies',
  '/user-edit': 'Cash register - User edit',
  '/change-pas': 'Cash register - Change password',

  '/users-list': 'Cash register - User cards balances',
  userCardBalances: 'Users cards balances',
  userCard: 'User card',
  accountBalance: 'Account balance',
  nickname: 'Nickname',
  id: 'Id',
  balance: 'Balance',
  ban: 'Ban',
  banSuccess: 'Ban success',
  unbanSuccess: 'Unban success',
  unban: 'Unban',
  resetPassword: 'Reset password',
  resetSuccess: 'Reset password success',
  networkError: 'Network error',
  createSuccess: 'Create success',
  topUp: 'TopUp',
  withdrawal: 'Withdrawal',
  topUpSuccess: 'TopUp success',
  withdrawalSuccess: 'Withdrawal success',
  topUpWithdrawal: 'TopUp/Withdrawal',
  ok: 'Ok',
  theNewPassword: 'The new password',
  for: 'for',

  '/transaction-history': 'Partners - Transaction history',
  startDate: 'Start date',
  endingDate: 'Ending date',
  display: 'Display',
  email: 'E-mail',
  date: 'Date',
  amount: 'Amount',

  languageSelection: 'Language selection',
  login: 'Login',

  cashRegister: 'Cash register',
  createUser: {
    1: 'New user',
    2: 'New shop',
    3: 'New admin',
  },
  balances: {
    1: 'User cards balances',
    2: 'Shop cards balances',
    3: 'Admins cards balances',
  },
  cashiersTransactions: {
    1: 'Cashier transactions',
    2: 'Shop transactions',
    3: 'Admins transactions',
  },
  partners: 'Partners',
  revenuBalances: 'Revenu balances',
  transactionHistory: {
    1: 'Transaction history',
    2: 'Transaction history',
    3: 'Transaction history',
  },

  total: 'Total',
  term: 'Term',
  deposit: 'Deposit',
  withdraw: 'Withdraw',
  cashPayment: 'Cash payment',
  cashPayout: 'Cash payout',
  withdraw: 'Withdraw',
  deposit: 'Deposit',
  search: 'Search',
  errorPassword: 'Password must consist of: at least 8 characters, at least one number, one upper and lower case letters!',
  noPass: 'Password must not be empty!',
  dataUpdate: 'Data is updated once every 12 hours.Therefore, the data for the current day may be incorrect.',
};
