import { toFixedHard } from "shared/helpers/toFixedHard";

export class TotalConverter {
  totalConverter = (data) => ({
    info: {
      credits: toFixedHard(Math.floor(data.credits_sum * 100) / 100),
      debits: toFixedHard(Math.floor(data.debits_sum * 100) / 100),
      total: toFixedHard(Math.floor(data.total * 100) / 100),
    },
    users: data.descendants.map(t => ({
      userId: t.user_id,
      username: t.username,
    }))
  });

  fullTotalConverter = (data) => ({
    info: {
      credits: toFixedHard(Math.floor(data.credits_sum * 100) / 100),
      debits: toFixedHard(Math.floor(data.debits_sum * 100) / 100),
      total: toFixedHard(Math.floor(data.total * 100) / 100),
    },
    users: data.descendants.map(t => ({
      userId: t.user_id,
      username: t.username,
      ...this.convertUser(t)
    }))
  });

  convertUser = data => ({
    credits: toFixedHard(Math.floor(data.credits_sum * 100) / 100),
    debits: toFixedHard(Math.floor(data.debits_sum * 100) / 100),
    total: toFixedHard(Math.floor(data.total * 100) / 100),
  });
}
