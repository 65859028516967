import { toFixedHard } from "shared/helpers/toFixedHard";

export class UserApiConverter {

  convertUsersList = data => ({
    users: data?.users || [],
    quantity: data?.quantity || 0,
    totalBalance: toFixedHard(data?.balance_sum || 0),
  });

  convertNewPassword = data => data.new_password;
}